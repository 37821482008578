<template>
	<div class="NftDetails">
		<div class="NftDetails-head">
			<my-nft-list-nav :list="nav_list" :index="nav_index" @click="navTap"/>
		</div>
		<div class="NftDetails-content">
			<div class="NftDetails-content__head">
				<div class="NftDetails-content__card">
					<img src="@/assets/bg_revoke.svg" alt="">
					<img class="content-img" :src="getCardImgUrl(card_info.key)" alt="">
				</div>
				<div class="NftDetails-content__info">
					<div class="name-box">
						<span class="name">{{ language === 'zhHans' || language === 'zhHant' ? card_data.zhHans : card_data.en }}</span>
					</div>
					<div class="item">
						<div class="d-flex align-center item-left">
							<span class="badges"></span>
							<span>{{ $t('OWN') }}</span>
						</div>
						<span class="item-value">{{ own }}</span>
					</div>
					<div class="item">
						<div class="d-flex align-center item-left">
							<span class="badges"></span>
							<span>{{ $t('STAKE') }}</span>
						</div>
						<span class="item-value">{{ is_synthetic_card ? stake_for_farm : stake_for_mine }}</span>
					</div>
					<div class="item" v-if="!is_synthetic_card">
						<div class="d-flex align-center item-left">
							<span class="badges"></span>
							<span>{{ $t('MINING POWER') }}</span>
						</div>
						<span class="item-value">{{ card_data.mining_power }}</span>
					</div>
					<div class="item" v-if="is_synthetic_card">
						<div class="d-flex align-center item-left">
							<span class="badges"></span>
							<span>{{ $t('GOD STRENGTH') }}</span>
						</div>
						<span class="item-value">{{ card_data.god_strength }}</span>
					</div>
					<div class="item" v-if="is_synthetic_card">
						<div class="d-flex align-center item-left">
							<span class="badges"></span>
							<span>{{ $t('BONUS PROPORTION') }}</span>
						</div>
						<span class="item-value">{{ card_data.bonus_proportion }}%</span>
					</div>
				</div>
			</div>
			<div class="d-flex flex-wrap">
				<v-btn
					class="mr-5 mb-5"
					:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
					color="#EB6930"
					large
					outlined
					rounded
					@click="_combined"
					:disabled="combined_disabled || Number(own) < 1"
				>{{ $t('COMBINED') }}</v-btn>
				<v-btn
					class="mr-5 mb-5"
					:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
					color="#EB6930"
					large
					outlined
					rounded
					:disabled="own === '0'"
					@click="mine_dialog = true"
				>{{ is_synthetic_card ? $t('STAKE FOR FARM') : $t('STAKE FOR MINE') }}</v-btn>
				<v-btn
					class="mr-5"
					:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
					color="#EB6930"
					large
					outlined
					rounded
					:disabled="own === '0'"
					@click="transfer_dialog = true"
				>{{ $t('TRANSFER') }}</v-btn>
				<v-btn
					class="mr-5 mb-5"
					:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
					color="#EB6930"
					large
					outlined
					rounded
					:disabled="own === '0'"
					@click="_transaction(`/app/Sell?index=${nav_index}`)"
				>{{ $t('SELL') }}</v-btn>
<!--				<v-btn-->
<!--					class="mr-5 mb-5"-->
<!--					:style="is_mobile ? mobile_btn_styles : pc_btn_styles"-->
<!--					color="#EB6930"-->
<!--					large-->
<!--					outlined-->
<!--					rounded-->
<!--					:disabled="own === '0'"-->
<!--					@click="_transaction(`/app/Auction?index=${nav_index}`)"-->
<!--				>{{ $t('AUCTION') }}</v-btn>-->
				<v-btn
					v-if="is_synthetic_card"
					:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
					color="#EB6930"
					large
					outlined
					rounded
					:loading="bonus_loading"
					:disabled="own === '0'"
					@click="_swapForBonuses"
				>{{ $t('REDEEM BONUS') }}</v-btn>
			</div>
		</div>
		<combine-guide/>
<!--	合成	-->
		<v-dialog v-model="combinedDialog" max-width="760px">
			<div class="c-dialog">
				<div class="c-dialog__head">
					<span>{{ $t('COMBINED') }}</span>
					<v-btn @click="combinedDialog = false" icon>
						<v-icon color="#EB6930">mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="c-dialog__content">
					<div class="hecheng-box">
						<div class="NftDetails-dialog__card">
							<img src="@/assets/bg_revoke.svg" alt="">
							<img class="content-img" :src="getCardImgUrl(card_info.key)" alt="">
						</div>
						<div class="NftDetails-dialog__right">
							<div class="name mb-5">{{ card_data.zhHans }}</div>
							<div class="select-box">
								<v-radio-group v-model="radioGroup">
									<v-radio
											v-for="(n, i) in card_data.combined_list"
											:key="n"
											:label="card_list[n].zhHans"
											color="success"
											:value="i"
									></v-radio>
								</v-radio-group>
							</div>
							<t-button class="btn" width="298px" height="60px" font-size="18px" @click="_combi" v-if="!is_mobile">{{ $t('COMBIEND') }}</t-button>
						</div>
					</div>

					<t-button class="btn" height="36px" font-size="14px" @click="_combi" v-if="is_mobile">{{ $t('COMBIEND') }}</t-button>
				</div>
			</div>
		</v-dialog>
<!--	合成	-->
		<v-dialog v-model="combinedConfirmDialog" max-width="1031px">
			<div class="c-dialog">
				<div class="c-dialog__head">
					<span>{{ synthesis_status ? $t('Purchase Success') : $t('COMBIEND') }}</span>
					<v-btn @click="combinedConfirmDialog = false" icon>
						<v-icon color="#EB6930">mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="c-dialog__content" v-if="card_data.combined.length">
					<div class="d-flex justify-center" v-if="synthesis_status">
						<div class="card-container ma-0">
							<div class="card flip-vertical-right">
								<div class="card-front">
									<img class="open-card_img" src="@/assets/close_bg.png" alt="">
								</div>
								<div class="card-back">
									<div class="card-front_info">
										<div></div>
										<div class="text-right open-card_name">
											<div>{{ synthesis_data.zhHans }}</div>
											<div>{{ synthesis_data.en }}</div>
										</div>
									</div>
									<img class="open-card_img card-front_img" :src="synthesis_img" alt="">
									<img class="open-card_img card-front_bg" src="@/assets/open-bg.png" alt="">
								</div>
							</div>
						</div>
					</div>
					<div class="hc-card__box" v-else>
						<div class="hc-card__row" v-for="(items, index) in hc_list" :key="index">
							<div class="hc-card__item" v-for="(item, i) in items" :key="item">
								<img class="bg-img" src="@/assets/open-bg.png" alt="" v-if="hc_num_arr[(index * chunk_size) + i].num > 0">
								<img class="bg-img" src="@/assets/close_bg.png" alt="" v-else>
								<img class="content-img" :src="getCardImgUrl(item)" alt="" v-if="hc_num_arr[(index * chunk_size) + i].num > 0">
								<img class="content-img" :src="getCardImgUrl(item, 'close')" alt="" v-else>
								<div class="hc-card__content">
									<div class="hc-card__number">{{ hc_num_arr[(index * chunk_size) + i].num }}/{{ hc_num_arr[(index * chunk_size) + i].need_num }}</div>
									<div class="hc-card__name">
										<div>{{ card_list[item].zhHans }}</div>
										<div>{{ card_list[item].en }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center mt-10">
<!--						<button @click="test">HC</button>-->
						<v-btn
								:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
								color="#EB6930"
								rounded
								large
								@click="_closeSynthesis"
								v-if="synthesis_status">{{ $t('confirm') }}</v-btn>
						<v-btn
								:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
								color="#EB6930"
								:loading="combi_loading"
								rounded
								large
								:disabled="combi_disabled"
								@click="_combiHandle"
								v-else
						>{{ $t('COMBIEND') }}</v-btn>
					</div>
				</div>
			</div>
		</v-dialog>
<!--	转账	-->
		<v-dialog v-model="transfer_dialog" max-width="760px">
			<div class="c-dialog">
				<div class="c-dialog__head">
					<span>{{ $t('TRANSFER') }}</span>
					<v-btn @click="transfer_dialog = false" icon>
						<v-icon color="#EB6930">mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="c-dialog__content NftDetails-dialog">
					<div class="NftDetails-dialog__card">
						<img src="@/assets/bg_revoke.svg" alt="">
						<img class="content-img" :src="getCardImgUrl(card_info.key)" alt="">
						<div class="m-card_name" v-show="is_mobile">{{ card_data.zhHans }}</div>
					</div>
					<div class="NftDetails-dialog__right">
						<div class="name mb-12" v-show="!is_mobile">{{ card_data.zhHans }}</div>
						<div class="mine-label" :class="[is_mobile ? 'mb-2' : 'mb-4']">{{ $t('TRANSFER NUM') }}</div>
						<div class="input" :class="[is_mobile ? 'mb-5' : 'mb-50']">
							<input type="number" v-model="transfer_number">
						</div>
						<div class="mine-label" :class="[is_mobile ? 'mb-2' : 'mb-4']">{{ $t('TRANSFER TO (WALLET ADDRESS)') }}</div>
						<div class="input">
							<input type="text" v-model="to_address">
						</div>
						<!--					<t-button class="mt-94" width="298px" height="60px" font-size="18px" @click="transfer" loading>TRANSFER</t-button>-->
						<v-btn
							:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
							:class="[is_mobile ? 'mt-7' : 'mt-10']"
							color="#EB6930"
							:loading="transfer_loading"
							rounded
							large
							block
							@click="transfer"
						>{{ $t('TRANSFER') }}
						</v-btn>
					</div>
				</div>
			</div>
		</v-dialog>
<!--	质押	-->
		<v-dialog v-model="mine_dialog" max-width="760px">
			<div class="c-dialog">
				<div class="c-dialog__head">
					<span>{{ is_synthetic_card ? $t('STAKE FOR FARM') : $t('STAKE FOR MINE') }}</span>
					<v-btn @click="mine_dialog = false" icon>
						<v-icon color="#EB6930">mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="c-dialog__content NftDetails-dialog">
					<div class="NftDetails-dialog__card">
						<img src="@/assets/bg_revoke.svg" alt="">
						<img class="content-img" :src="getCardImgUrl(card_info.key)" alt="">
						<div class="m-mining_absolute" v-show="is_mobile">
							<div class="name">{{ card_data.zhHans }}</div>
							<div class="m-mining_absolute-item">
								<p>{{ $t('TOTAL') }}</p>
								<p>{{ card_info.num }}</p>
							</div>
							<div class="m-mining_absolute-item" v-if="is_synthetic_card">
								<p>{{ $t('STAKE FOR FARM') }}</p>
								<p>{{ stake_for_farm }}</p>
							</div>
							<div class="m-mining_absolute-item" :class="[is_mobile ? 'mb-4' : 'mb-10']" v-if="!is_synthetic_card">
								<p>{{ $t('STAKE FOR MINE') }}</p>
								<p>{{ stake_for_mine }}</p>
							</div>
						</div>
					</div>
					<div class="NftDetails-dialog__right">
						<div class="name mb-10" v-show="!is_mobile">{{ card_data.zhHans }}</div>
						<div class="NftDetails-text__item" v-if="!is_mobile">
							<span>{{ $t('TOTAL') }}</span>
							<span>{{ card_info.num }}</span>
						</div>
						<div class="NftDetails-text__item" v-if="is_synthetic_card && !is_mobile">
							<span>{{ $t('STAKE FOR FARM') }}</span>
							<span>{{ stake_for_farm }}</span>
						</div>
						<div class="NftDetails-text__item" :class="[is_mobile ? 'mb-4' : 'mb-10']" v-if="!is_synthetic_card && !is_mobile">
							<span>{{ $t('STAKE FOR MINE') }}</span>
							<span>{{ stake_for_mine }}</span>
						</div>
						<div class="mine-label mb-4">{{ $t('NUM YOU WANT TO STAKE') }}</div>
						<div class="input">
							<input type="number" v-model="mine_number">
						</div>
						<v-btn
								:style="is_mobile ? mobile_btn_styles : pc_btn_styles"
								:class="[is_mobile ? 'mt-7' : 'mt-10']"
								color="#EB6930"
								:loading="mine_loading"
								rounded
								large
								block
								@click="_deposit"
						>
							<span v-if="mine_approve">{{ is_synthetic_card ? $t('STAKE FOR FARM') : $t('STAKE FOR MINE') }}</span>
							<span v-else>{{ $t('APPROVE') }}</span>
						</v-btn>
					</div>
				</div>
			</div>
		</v-dialog>
	</div>
</template>

<script>
import { getCardImgUrl } from '@/utils/tools'
import { mapState } from 'vuex'
import {
	approveNftAll,
	approveStakingPool,
	batchCardTransferFrom,
	cardStakingContract,
	combine,
	dividend_address,
	isApproveStakingPool,
	nftDividendDeposit,
	staking_address, swapForBonuses,
} from '@/utils/cardFactory'
import MyNftListNav from '@/views/Game/MyNFT/MyNftListNav'
import CombineGuide from '@/views/Game/CombineGuide'
import CardLis from '@/data/card'
import _ from 'lodash'
import JSBI from 'jsbi'
import { SessionStorage } from 'storage-manager-js'

export default {
	name: 'NftDetails',
	components: {
		CombineGuide,
		MyNftListNav,
	},
	data () {
		return {
			combinedDialog: false,
			radioGroup: 0,
			combinedConfirmDialog: false,
			transfer_dialog: false,
			mine_dialog: false,
			mine_number: 1,
			mine_loading: false,
			mine_approve: false,
			// 传入卡片
			card_info: null,
			to_address: '',
			transfer_loading: false,
			transfer_number: 1,
			card_data: null,
			card_list: CardLis,
			combined_disabled: false,
			hc_list: [],
			hc_num_arr: [],
			combi_disabled: true,
			combi_loading: false,
			bonus_loading: false,
			nav_list: '',
			nav_index: 0,
			pc_btn_styles: {
				height: '52px !important',
			},
			mobile_btn_styles: {
				height: '34px !important',
			},
			my_card_list: [],
			synthesis_status: false,
			synthesis_img: '',
			synthesis_data: null,
		}
	},
	computed: {
		...mapState(['ADDRESS', 'language']),
		is_synthetic_card () {
			return this.card_info.key.split('-')[0] === '9'
		},
		own () {
			const x = JSBI.BigInt(this.card_info.num)
			const y = JSBI.BigInt(this.stake_for_farm)
			const x1 = JSBI.subtract(x, y)
			return String(JSBI.subtract(x1, JSBI.BigInt(this.stake_for_mine)))
		},
		stake_for_farm () {
			return this.card_info.dividend ? this.card_info.dividend.length : 0
		},
		stake_for_mine () {
			return this.card_info.staking ? this.card_info.staking.length : 0
		},
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
		chunk_size () {
			return this.is_mobile ? 2 : 4
		},
	},
	created () {
		const index = this.$route.query.index
		if (index) {
			this.nav_index = Number(index)
		}
		this.nav_list = SessionStorage.get('NFT_NAV_LIST')
		const query = SessionStorage.get('CARD_INFO')
		this.my_card_list = SessionStorage.get('MY_CARDS')

		this.card_info = { ...query, num: Number(query.num) }
		this.card_data = CardLis[this.card_info.key]
		if (this.card_data.combined_list.length === 0) {
			this.combined_disabled = true
		}
	},
	mounted () {
		if (this.ADDRESS) {
			this._isApproveStakingPool()
		}
	},
	methods: {
		_closeSynthesis () {
			this.combinedDialog = false
			this.combinedConfirmDialog = false
		},
		navTap ({ index }) {
			this.$router.push({
				path: '/app/Game/MyNFT',
				query: { index },
			})
		},
		_transaction (url) {
			const data = { ...this.card_info, is_synthetic_card: this.is_synthetic_card, data: this.card_list[this.card_info.key], own: this.own, stake_for_farm: this.stake_for_farm, stake_for_mine: this.stake_for_mine }
			SessionStorage.set('TRANSACTION_CARD', data)
			this.$router.push(url)
		},
		async _swapForBonuses () {
			this.bonus_loading = true
			try {
				await swapForBonuses(this.ADDRESS, this.card_info.ids[0])
				this.bonus_loading = false
				this.$router.push('/app/BonusesPool')
			} catch (err) {
				console.log(err)
				this.bonus_loading = false
			}
		},
		synthesisSuccess () {
			this.synthesis_img = getCardImgUrl(this.card_data.combined_list[this.radioGroup])
			this.synthesis_status = true
			this.synthesis_data = this.card_list[this.card_data.combined_list[this.radioGroup]]
		},
		// 合成卡牌
		async _combiHandle () {
			const tokens = []
			this.hc_num_arr.map(item => {
				tokens.push(item.ids[0])
			})
			this.combi_loading = true
			try {
				const result = await combine(this.ADDRESS, tokens, this.card_list[this.card_info.key].combined_list[this.radioGroup].split('-')[1])
				console.info(result)
				this.combi_loading = false
				this.$toast.success(this.$t('Combination success'))
				this.synthesisSuccess()
				// this.$router.push('/app/Game/MyNFT')
			} catch (error) {
				this.combi_loading = false
			}
		},
		getCardImgUrl (filename, type) {
			return getCardImgUrl(filename, type)
		},
		// 合成弹层显示
		_combi () {
			this.combinedConfirmDialog = true
			const arr0 = this.card_data.combined[this.radioGroup][0]
			const arr1 = this.card_data.combined[this.radioGroup][1]
			this.hc_num_arr = []
			arr0.map((key, index) => {
				this.hc_num_arr.push({
					need_num: arr1[index],
					num: 0,
				})
			})
			// console.log(this.my_card_list)
			this.my_card_list.map(item => {
				arr0.map((key, index) => {
					if (key === item.key) {
						this.hc_num_arr[index] = { ...this.hc_num_arr[index], ...item }
					}
				})
			})
			this.hc_list = _.chunk(arr0, this.chunk_size)

			let num = 0
			// 合成按钮状态
			this.hc_num_arr.map(item => {
				if (item.num >= item.need_num) {
					num++
				}
			})
			if (num === this.hc_num_arr.length) {
				this.combi_disabled = false
			}
		},
		// 合成
		_combined () {
			this.combinedDialog = true
		},
		// 挖矿、分红是否授权
		async _isApproveStakingPool () {
			if (this.is_synthetic_card) {
				this.mine_approve = await approveNftAll(this.ADDRESS, staking_address, false)
			} else {
				this.mine_approve = await isApproveStakingPool(this.ADDRESS)
			}
		},
		// 分红授权
		async _bonusApprove () {
			this.mine_approve = await approveNftAll(this.ADDRESS, dividend_address, true)
			this.mine_approve = true
			this.mine_loading = false
		},
		// 挖矿授权
		async _approveStakingPool () {
			await approveStakingPool(this.ADDRESS)
			this.mine_approve = true
			this.mine_loading = false
		},
		// 转账
		async transfer () {
			if (this.to !== '' && this.transfer_number <= this.card_info.num) {
				this.transfer_loading = true
				try {
					await batchCardTransferFrom(this.ADDRESS, this.to_address, this.card_info.ids.slice(0, this.transfer_number))
					this.$toast.success(this.$t('Successful transfer'))
					this.transfer_loading = false
				} catch (error) {
					this.transfer_loading = false
				}
			}
		},
		// 质押挖矿
		async _bonus () {
			if (this.mine_approve && this.mine_number <= this.card_info.num) {
				try {
					await nftDividendDeposit(this.ADDRESS, this.card_info.ids.slice(0, this.mine_number))
					this.mine_loading = false
					this.$router.push('/app/FarmingPool')
				} catch (err) {
					console.log(err)
					this.mine_loading = false
				}
			} else {
				this._bonusApprove()
			}
		},
		// 质押分红
		async _deposit () {
			if (Number(this.mine_number) < 1 && Number(this.mine_number) > Number(this.card_info.num)) {
				return false
			}
			this.mine_loading = true
			if (this.is_synthetic_card) {
				this._bonus()
			} else {
				if (this.mine_approve) {
					try {
						await cardStakingContract.methods.deposit(this.card_info.ids.slice(0, this.mine_number)).send({ from: this.ADDRESS })
						this.mine_loading = false
						this.$toast.success(this.$t('Successful'))
						this.$router.push('/app/MiningPool')
					} catch (error) {
						this.mine_loading = false
					}
				} else {
					this._approveStakingPool()
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
// pc
@media (min-width: 960px) {}
// mobile
@media (max-width: 960px) {}
.hc-card {
	&__row {
		display: flex;
		justify-content: center;
		margin-bottom: 15px;
	}
	&__number {
		font-weight: bold;
		font-size: 14px;
		line-height: 19px;
		color: #2D3339;
	}
	&__name {
		font-weight: bold;
		font-size: 12px;
		line-height: 19px;
		color: #2D3339;
		text-align: right;
	}
	&__content {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 15px;
		z-index: 999;
	}
	&__item {
		img {
			vertical-align: middle;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
		&:last-child {
			margin-right: 0;
		}
		.content-img {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}
		.bg-img {}
		position: relative;
		margin-right: 15px;
		max-width: 200px;
		width: 100%;
	}
}
</style>
