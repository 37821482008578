export default [
	{
		name: '熱烈歓迎了法寺',
		need: '金宇賀様、聖夜祭、陸弁天、捌弁天、母、天照大神',
		name_en: 'Welcome to Ryohoji',
		need_en: 'Golden Uga、Christmas、Benten Ⅵ、Benten Ⅷ、Mother、Amaterasu',
		point: '18%',
	},
	{
		name: '白山羊年',
		need: '白山午鳥、白山未年、捌小弁天、素戔嗚尊',
		name_en: 'God Sheep',
		need_en: 'Horse Bird、Sheep、Small Benten Ⅷ、Susanoo',
		point: '1%',
	},
	{
		name: '白山鳳凰',
		need: '白山酉年、白山酉、白山酉娘、白山酉鳥、伍小弁天、素戔嗚尊',
		name_en: 'God Phoenix',
		need_en: 'Bird、Phoenix、Bird Girl、Flying Bird、Small Benten Ⅴ、Susanoo',
		point: '5%',
	},
	{
		name: '白山寅年',
		need: '白山寅年、白山寅娘、白山寅、弍弁天、素戔嗚尊',
		name_en: 'God Tiger',
		need_en: 'Tiger、Tiger Girl、Tiger Bird、Benten Ⅱ、Susanoo',
		point: '5%',
	},
	{
		name: '白山猿年',
		need: '白山申年、白山申、白山申娘、白山申鳥、肆小弁天、大国主命',
		name_en: 'God Monkey',
		need_en: 'Monkey、Smiling Monkey、Monkey Girl、Monkey Bird、Small Benten Ⅳ、Okuninushi',
		point: '5%',
	},
	{
		name: '白山牛年',
		need: '白山丑年、白山丑娘、白山丑、壱弁天、歌姉、伍吉祥天、大国主命',
		name_en: 'God Ox',
		need_en: 'Ox、Ox Girl、Ox Bird、Benten Ⅰ、Song 、Kisshoten Ⅴ、Okuninushi',
		point: '5%',
	},
	{
		name: '白岩白山神社物語',
		need: '白山神社物語、白山神社裏物語、泰澄、大役小角、白山神社御朱印帳、白山神社御朱印帳男子、大国主命',
		name_en: 'Shiraiwa-Hakusan-Story',
		need_en: 'Hakusan-Story、Hakusan-Another-Story、Taicho、Great Ozunu、Hakusan Gosyuin note 、Hakusan Gosyuin note Boy、Okuninushi',
		point: '13%',
	},
	{
		name: '菊理暖命',
		need: '菊理姫、花弁、向日葵、姫、伊弉諾神',
		name_en: 'God Kukurihime ',
		need_en: 'Kukurihime 、Petals、Sunflower、Princess、Izanami ',
		point: '1%',
	},
	{
		name: '白山馬年',
		need: '白山午年、白山午娘、白山午鳥、巫女乃鳥、リース弁天、参小弁天、伊弉諾神',
		name_en: 'God Horse',
		need_en: 'Horse、Horse Girl、Horse Bird、Miko Bird、Wreath、Small Benten Ⅲ、Izanami ',
		point: '5%',
	},
	{
		name: '白山猪年',
		need: '白山猪、白山猪娘、漆小弁天、里奈也、弍里奈也、伊弉諾神',
		name_en: 'God Boar',
		need_en: 'Boar、Boar Girl、Small Benten Ⅶ、Rina Nari、Rina Nari Ⅱ、Izanami ',
		point: '5%',
	},
	{
		name: '稲荷神社',
		need: '稲荷右狐、稲荷左狐、稲荷神社娘、也、人形、伊弉諾神',
		name_en: 'Inari Shrine',
		need_en: 'Right Fox、Left Fox、Inari Shrine Girl、Nari、Doll、Izanami ',
		point: '5%',
	},
	{
		name: '白山神社初詣',
		need: '白山神社巫女、白山神社鳥、白山神社娘、陸弁天、漆弁天、印度娘、泡、伊弉諾神',
		name_en: 'New Year',
		need_en: 'Shrine Maiden、Shrine Bird、Shrine Girl、Benten Ⅵ、Benten Ⅶ、Indian girl、Bubble Time、Izanami ',
		point: '9%',
	},
	{
		name: '白山龍年',
		need: '白山辰、白山辰鳥、白山辰娘、白山辰年、壱小弁天、伊邪那美神',
		name_en: 'God Dragon',
		need_en: 'Dragon Car、Dragon Bird、Dragon Girl、Dragon 、Small Benten  Ⅰ、Izanagi ',
		point: '5%',
	},
	{
		name: '白山狐年',
		need: '白山戌年、白山戌鳥.、白山戌、白山戌娘、白山子戌、陸小弁天、伊邪那美神',
		name_en: 'God Fox',
		need_en: 'Dog、Dog Bird、Small Dogs、Dog Girl、Puppy、Small Benten Ⅵ、Izanagi ',
		point: '5%',
	},
	{
		name: '白山鼠年',
		need: '白山子年、巫女乃鳥、弁天、壱吉祥天、弍吉祥天、伊邪那美神',
		name_en: 'God Rat',
		need_en: 'Rat、Miko Bird、Benten、Kisshoten Ⅰ、Kisshoten Ⅱ、Izanagi ',
		point: '5%',
	},
	{
		name: '役小角物語',
		need: '鬼役小角、役小角、菊理比咩命、伊邪那美神',
		name_en: 'Story of Ozunu',
		need_en: 'Ogre Ozunu、Ozunu、Kukurihime-mikoto、Izanagi ',
		point: '5%',
	},
	{
		name: '巫女乃',
		need: '巫女乃、巫女乃花弁、巫女乃鳥、九尾、リース弁天、伊邪那美神',
		name_en: 'Super Miko',
		need_en: 'Miko petals、Miko Bird、Kyubi、Wreath、Izanagi ',
		point: '9%',
	},
	{
		name: '白山蛇年',
		need: '白山巳年、白山巳娘、白山巳鳥、七面様、幼女化七面様、弍小弁天、伊邪那美神',
		name_en: 'God Serpent',
		need_en: 'Serpent、Serpent Girl、Serpent Bird、Seven Faces、Little Girl Seven Faces、Small Benten Ⅱ、Izanagi ',
		point: '5%',
	},
]
