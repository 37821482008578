<template>
  <div class="MyNFTList-nav">
    <div class="MyNFTList-nav__item" :class="{'active': index === i}" v-for="(item, i) in list" :key="item.type" @click="_navItemTap(item, i)">{{ item.title }}  ({{ item.value }})</div>
  </div>
</template>

<script>
export default {
	name: 'MyNftListNav',
	props: {
		index: { type: Number, default: 0 },
		list: { type: Array },
	},
	data () {
		return {}
	},
	methods: {
		_navItemTap (item, index) {
			this.$emit('click', { item, index })
		},
	},
}
</script>

<style lang="scss" scoped>

</style>
