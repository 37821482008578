<template>
<div class="CombineGuide">
  <div class="CombineGuide-title">{{ $t('CombineGuide') }}</div>
  <table class="CombineGuide-table" v-show="!is_mobile">
    <tr>
      <th width="5%"></th>
      <th>{{ $t('NAME') }}</th>
      <th>{{ $t('NEED') }}</th>
      <th width="5%">{{ $t('POINT') }}</th>
    </tr>
    <tr v-for="(item, index) in combine_list" :key="index">
      <td>{{ index + 1 }}</td>
      <td>{{ language === 'zhHans' || language === 'zhHant' || language === 'ja' ? item.name : item.name_en }}</td>
      <td>{{ language === 'zhHans' || language === 'zhHant' || language === 'ja' ? item.need : item.need_en }}</td>
      <td>{{ item.point }}</td>
    </tr>
  </table>
	<div v-show="is_mobile">
		<div class="combine-card" v-for="(item, index) in combine_list" :key="index">
			<div class="combine-card_index">{{ index + 1 }}</div>
			<div class="combine-card_content">
				<div class="combine-card_item">
					<span>{{ $t('NAME') }}</span>
					<span>{{ language === 'zhHans' || language === 'zhHant' || language === 'ja' ? item.name : item.name_en }}</span>
				</div>
				<div class="combine-card_item">
					<span>{{ $t('NEED') }}</span>
					<span>{{ language === 'zhHans' || language === 'zhHant' || language === 'ja' ? item.need : item.need_en }}</span>
				</div>
				<div class="combine-card_item">
					<span>{{ $t('POINT') }}</span>
					<span>{{ item.point }}</span>
				</div>
			</div>
		</div>
	</div>
<!--  <div class="CombineGuide-line"></div>
  <div class="CombineGuide-more">
    <span>More</span>
    <v-icon color="#E95A29">mdi-chevron-down</v-icon>
  </div>-->
</div>
</template>

<script>
import combine from '@/data/combine'
import { mapState } from 'vuex'

export default {
	name: 'CombineGuide',
	data () {
		return {
			combine_list: combine,
		}
	},
	computed: {
		...mapState(['language']),
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
	},
}
</script>

<style lang="scss" scoped>
// pc
@media (min-width: 960px) {
	.CombineGuide {
		&-title {
			font-size: 24px;
			line-height: 36px;
			margin-bottom: 46px;
		}
		max-width: 732px;
		padding: 80px 15px;
		margin: 0 auto;
	}
}
// mobile
@media (max-width: 960px) {
	.CombineGuide {
		&-title {
			font-size: 14px;
			line-height: 21px;
			margin-bottom: 28px;
		}
		padding: 28px 0;
	}
}
.CombineGuide {
	.combine-card {
		&_item {
			&:last-child {
				margin-bottom: 0;
			}
			span {
				&:last-child {
					font-weight: 500;
					font-size: 14px;
					line-height: 21px;
					color: #ACB1B6;
				}
				&:first-child {
					font-weight: bold;
					font-size: 16px;
					line-height: 24px;
					color: #67717A;
					padding-right: 50px;
				}
				display: inline-block;
			}
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 12px;
		}
		&_index {
			font-weight: bold;
			font-size: 16px;
			line-height: 24px;
			color: #FFFFFF;
			width: 50px;
		}
		&_content {
			flex: 1;
		}
		display: flex;
		padding: 15px;
		border-bottom: 1px solid #202831;
	}
  &-more {
    span {
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      color: #E95A29;
      cursor: pointer;
    }
    text-align: right;
  }
  &-table {
    tr {
      &:last-child {
        td {
          padding-bottom: 0;
        }
      }
      td:last-child {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #E8E8E8;
      }
      td:first-child {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: #67717A;
      }
      td:nth-child(2), td:nth-child(3) {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #ACB1B6;
      }
      th {
        padding-bottom: 40px;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: #67717A;
        text-align: left;

      }
      td {
        padding-bottom: 30px;
      }
    }
    width: 100%;
  }
  &-title {
    font-weight: bold;
    text-align: center;
    color: #E8E8E8;
  }
  &-line {
    border-top: 1px solid #434A52;
    margin: 50px 0;
  }
}
</style>
